import { TAX_TYPE_PERCENTAGE } from "./taxes";

export default [
  {
    name: "TPS",
    type: TAX_TYPE_PERCENTAGE,
    number: null,
    value: 5,
  },
  {
    name: "TVQ",
    type: TAX_TYPE_PERCENTAGE,
    number: null,
    value: 9.975,
  },
];
