<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!salesInvoice">
      <span class="loader"></span>
    </span>
    <div v-if="salesInvoice" class="elite-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ salesInvoice.code }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.INVOICING_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("SALES_INVOICES.SALES_INVOICES_LIST") }}</span>
              </li>
              <li>
                <span>{{ salesInvoice.code }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <!--<base-button class="kw-button submit" icon size="sm">
            <span class="btn-inner--text">
              {{ "Soumettre" }}
            </span>
          </base-button>

          <base-button class="kw-button add" icon size="sm">
            <span class="btn-inner--icon">
              <img
                src="../../../../../../public/img/close-folio.svg"
                alt="icon"
              />
            </span>
            <span class="btn-inner--text">
              {{ $t("SALES_INVOICES.CLOSE_FOLIO") }}
            </span>
          </base-button>
          -->
        </div>
      </div>

      <!-- region client -->
      <div class="customer-details">
        <div class="customer-details-left">
          <dl>
            <dt>{{ $t("SALES_INVOICES.CUSTOMER_NAME") }} :</dt>
            <dd>
              {{
                salesInvoice.recipient.firstname +
                " " +
                salesInvoice.recipient.lastname
              }}
            </dd>
          </dl>
          <dl>
            <dt>{{ $t("COMMON.EMAIL") }} :</dt>
            <dd>
              {{ salesInvoice.recipient.email }}
            </dd>
          </dl>
          <dl>
            <dt>{{ $t("COMMON.PHONE") }} :</dt>
            <dd>
              {{ salesInvoice.recipient.phone }}
            </dd>
          </dl>
          <dl>
            <dt>{{ $t("COMMON.COUNTRY") }} :</dt>
            <dd>
              {{ salesInvoice.recipient.country }}
            </dd>
          </dl>
          <dl>
            <dt>{{ $t("COMMON.CITY") }} :</dt>
            <dd>
              {{ salesInvoice.recipient.city }}
            </dd>
          </dl>
        </div>
        <div class="customer-details-right">
          <!--<base-button class="kw-button edit" icon size="sm">
            <span class="btn-inner--text">
              {{ $t("SALES_INVOICES.MODIFY") }}
            </span>
          </base-button>
          <base-button class="kw-button" icon size="sm">
            <span class="btn-inner--text">
              {{ $t("SALES_INVOICES.CHANGE") }}
            </span>
          </base-button>-->
        </div>
        <div class="customer-details-status">
          <ul>
            <li>
              <span>{{ $t("COMMON.STATUS") }} : </span>
            </li>
            <li class="badge">
              <span>{{ $t(`SALES_INVOICES.INVOICE_STATUS_${salesInvoice.status}`) }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- endregion -->

      <!-- region infos facture -->
      <tabs
        fill
        class="facturation-details-tabs"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav kw-tabs"
        value="details"
      >
        <!-- region global -->
        <tab-pane title="global" id="1" :active="false">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <sales-invoice-view-global :salesInvoice="salesInvoice" />
        </tab-pane>
        <!-- endregion -->

        <!-- region details -->
        <tab-pane title="details" id="2" :active="false">
          <span slot="title">
            <i class="ni ni-bullet-list-67"></i>
            {{ $t("COMMON.DETAILS") }}
          </span>
          <sales-invoice-view-details
            :salesInvoice="salesInvoice"
            @salesInvoiceItemsUpdated="get"
          />
        </tab-pane>
        <!-- endregion -->

        <!-- region paiements -->
        <tab-pane
          title="payments"
          id="3"
          :active="false"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SALES_PAYMENTS)"
        >
          <span slot="title">
            <i class="ni ni-money-coins"></i>
            {{ $t("COMMON.PAYMENTS") }}
          </span>
          <sales-invoice-view-payments :salesInvoice="salesInvoice" />
        </tab-pane>
        <!-- endregion -->

        <tab-pane title="invoice" id="4">
          <span slot="title">
            <i class="fa fa-file-pdf"></i>
            {{ $t("SALES_INVOICES.SALES_INVOICE") }}
          </span>
          <iframe :src="invoiceDownloadUrl" type="application/pdf" width="100%" height="1500px"/>
        </tab-pane>

        <!-- region fichiers -->
        <tab-pane
          title="files"
          id="998"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            salesInvoice.organization
          "
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="salesInvoice" />
          </div>
        </tab-pane>
        <!-- endregion -->

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <sales-invoice-view-logs :salesInvoice="salesInvoice" />
        </tab-pane>
      </tabs>
      <!-- -->
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import SalesInvoiceViewGlobal from "../partials/SalesInvoiceViewGlobal.vue";
import SalesInvoiceViewDetails from "../partials/SalesInvoiceViewDetails.vue";
import SalesInvoiceViewLogs from "../partials/SalesInvoiceViewLogs.vue";
import SalesInvoiceViewPayments from "../partials/SalesInvoiceViewPayments.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    SalesInvoiceViewGlobal,
    SalesInvoiceViewDetails,
    SalesInvoiceViewLogs,
    ListFileComponent,
    SalesInvoiceViewPayments,
  },

  mixins: [requestErrorMixin],

  props: {
    salesInvoiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      salesInvoice: null,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
    };
  },

  computed: {
    canCancelSalesInvoice() {
      if (
        this.salesInvoice.status === INVOICE_STATUS_CANCELED ||
        this.salesInvoice.status === INVOICE_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.salesInvoice.status === INVOICE_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },
    invoiceDownloadUrl()
    {
      if(this.salesInvoice) {
        return `${process.env.VUE_APP_API_BASE_URL}/public/sales-invoices/${this.salesInvoice.code}/stream` ;
      }

      return "";
    }
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("salesInvoices/get", this.salesInvoiceId);
        this.salesInvoice = this.$store.getters["salesInvoices/salesInvoice"];
        this.$emit("salesInvoiceLoaded", this.salesInvoice);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
