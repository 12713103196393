export const PAYMENT_STATUS_DRAFT = "DRAFT";
export const PAYMENT_STATUS_COMPLETED = "COMPLETED";
export const PAYMENT_STATUS_CANCELED = "CANCELED";
export const PAYMENT_STATUS_ERROR = "ERROR";

export const PAYMENT_SOURCE_MANUAL = "MANUAL";
export const PAYMENT_SOURCE_STRIPE = "STRIPE";
export const PAYMENT_SOURCE_PAYPAL = "PAYPAL";
export const PAYMENT_SOURCE_CASH = "CASH";

export const PAYMENT_OBJECT_TYPE = "sales-payments";

export const paymentStatusesOption = {
  [PAYMENT_STATUS_DRAFT]: "Brouillon",
  [PAYMENT_STATUS_COMPLETED]: "Completé",
  [PAYMENT_STATUS_CANCELED]: "Annulé",
  [PAYMENT_STATUS_ERROR]: "Erreur",
};

export const paymentSourcesOption = {
  [PAYMENT_SOURCE_MANUAL]: "Manuel",
  [PAYMENT_SOURCE_STRIPE]: "Stripe",
  [PAYMENT_SOURCE_PAYPAL]: "Paypal",
  [PAYMENT_SOURCE_CASH]: "Cash",
};
