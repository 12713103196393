<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <div class="page-wrapper-table-header-left">
        <base-input
          v-model="query"
          type="search"
          class="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
        <el-select
          v-if="!filterStatus"
          :placeholder="$t('COMMON.STATUS')"
          v-model="selectedStatus"
        >
          <el-option :value="0" :label="$t('COMMON.ALL_STATUSES')"> </el-option>
          <el-option
            v-for="(value, key) in statusesOptions"
            :key="key"
            :value="key"
            :label="value"
          >
          </el-option>
        </el-select>

        <organization-selector
          v-if="
            !filterOrganization &&
            !filterRecipient &&
            !filterSalesOrder &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />

        <base-input
          v-if="
            !filterSalesOrder &&
            $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)
          "
          :placeholder="`${$t('COMMON.LOCATIONS')}`"
        >
          <locations-selector
            @locationsChanged="(locations) => (selectedLocations = locations)"
          />
        </base-input>

        <base-input class="dates" placeholder="Dates">
          <flat-picker
            :config="{
              allowInput: true,
              mode: 'range',
              locale: flatPickrLocale,
            }"
            class="form-control datepicker"
            v-model="dateRange"
            placeholder="Dates"
          >
          </flat-picker>
        </base-input>
      </div>
      <div class="page-wrapper-table-header-right">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="page-wrapper-table-body">
      <div class="table-loading" slot="empty" v-if="loading">
        <img src="/img/loading.gif" />
      </div>

      <div
        class="page-wrapper-table-body-inner"
        v-bind:style="{ visibility: loading ? 'hidden' : 'visible' }"
      >
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="salesInvoices"
          :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
          @sort-change="sortChange"
        >
          <!-- No de factures -->
          <el-table-column
            v-if="!invoiceType || invoiceType.toLowerCase() === 'invoices'"
            prop="code"
            min-width="220"
            sortable="custom"
            :label="this.$t('SALES_INVOICES.SALES_INVOICES_CODE_LABEL')"
          >
          </el-table-column>

          <!-- No de folio -->
          <el-table-column
            :label="$t('SALES_INVOICES.FOLIOS_CODE_LABEL')"
            prop="code_folio"
            sortable="custom"
            min-width="220"
          >
          </el-table-column>

          <!-- booking number -->
          <el-table-column
            v-if="
              $currentUserCan($permissions.PERM_VIEW_BOOKINGS) &&
              !filterSalesOrder
            "
            :label="$t('SALES_INVOICES.ASSOCIATED_BOOKING')"
            min-width="240"
          >
            <template v-slot="{ row }">
              <el-popover placement="bottom" width="400" trigger="click">
                <el-table
                  class="permissions-list"
                  :data="row.items"
                  height="250"
                >
                  <el-table-column
                    min-width="150"
                    property="key"
                    prop="code"
                    :label="`${$t('BOOKINGS.BOOKING_LIST')} (${row.items?.length ?? 0})`">
                    <template v-slot="{ row }">
                      <object-link :object="row.salesInvoiceable" />
                    </template>
                  </el-table-column>
                </el-table>
                <el-button
                  class="view-permissions-button"
                  :title="`${$t('COMMON.VIEW')} (${row.items?.length ?? 0})`"
                  slot="reference"
                >
                  {{ $t("COMMON.VIEW") }} ({{ row.items?.length ?? 0 }})
                </el-button>
              </el-popover>
            </template>
          </el-table-column>

          <!-- created at -->
          <el-table-column
            :label="$t('COMMON.DATE_TIME')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatDate(row.created_at, "lll") }}
            </template>
          </el-table-column>

          <!-- client -->
          <el-table-column
              :label="$t('COMMON.CUSTOMER')"
              min-width="220">
            <template v-slot="{ row }">
              <object-link v-if="$currentUserCan($permissions.PERM_VIEW_CUSTOMERS)" :object="row.recipient"/>
              <span v-else> {{ `${row.recipient.firstname} ${row.recipient.lastname}` }} </span>
            </template>
          </el-table-column>

          <!-- couriel -->
          <el-table-column :label="$t('COMMON.EMAIL')" min-width="200">
            <template v-slot="{ row }">
              {{ row.recipient.email }}
            </template>
          </el-table-column>

          <!-- numero de telephone -->
          <el-table-column :label="$t('CUSTOMERS.PHONE_SHORT')" min-width="200">
            <template v-slot="{ row }">
              {{ row.recipient.phone }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.AMOUNT')"
            prop="pricing.total"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ $formatCurrency(row.pricing.total) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.LOCATIONS')"
            sortable="custom"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
            min-width="220"
          >
            <template v-slot="{ row }">
              <locations :locations="row.allowedLocations" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.ORGANIZATION')"
            sortable="custom"
            v-if="
              !filterOrganization &&
              !filterSalesOrder &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            min-width="220"
          >
            <template v-slot="{ row }">
              <organization :organization="row.organization" />
            </template>
          </el-table-column>

          <!-- expiration date -->
          <el-table-column
            :label="$t('SALES_INVOICES.EXPIRATION_TIME')"
            prop="expiration_time"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatDate(row.expiration_time, "lll") }}
            </template>
          </el-table-column>

          <!-- status -->
          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            min-width="200"
          >
            <template slot-scope="{ row }">
              <sales-invoice-status-badge :salesInvoice="row" />
            </template>
          </el-table-column>

          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_SALES_INVOICES)"
              >
                <a
                  type="text"
                  @click="viewSalesInvoice(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="
                  $currentUserCan($permissions.PERM_EDIT_SALES_INVOICES) &&
                  row.status === INVOICE_STATUS_DRAFT
                "
              >
                <a
                  type="text"
                  @click="editSalesInvoice(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                content="Delete"
                placement="top"
                v-if="
                  $currentUserCan($permissions.PERM_DELETE_SALES_INVOICES) &&
                  row.status === INVOICE_STATUS_DRAFT
                "
              >
                <a
                  type="text"
                  @click="deleteSalesInvoice(row.id)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-binvoice"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  invoiceStatusesOption,
  INVOICE_STATUS_DRAFT,
} from "@/constants/invoices";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SalesInvoiceStatusBadge from "./SalesInvoiceStatusBadge.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";

export default {
  name: "sales-invoice-list-table",

  components: {
    LoadingPanel,
    BasePagination,
    flatPicker,
    OrganizationSelector,
    LocationsSelector,
    SalesInvoiceStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterSalesOrder: {
      type: String,
      default: null,
      description: "Order id",
    },
    filterRecipient: {
      type: Object,
      default: null,
      description: "Object with 'recipient_id' and 'recipient_type' ",
    },
    filterStatus: {
      type: [Array],
      default: null,
      description: "Status",
    },
    invoiceType: {
      default: "invoices",
      type: String,
      description:
        "Le type de facture que l'on affiche, soit invoices, soit folios.",
    },
  },

  data() {
    return {
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50, 100, 500],
      },
      salesInvoices: [],
      loading: false,
      dateRange: null,
      selectedOrganization: null,
      selectedLocations: null,
      selectedStatus: null,
      selectedRecipient: null,
      statusesOptions: invoiceStatusesOption,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
    };
  },

  async created() {
    await this.getList();
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterRecipient: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedRecipient: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterSalesOrder: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];

        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(vDateRange.length == 2
              ? {
                  created_at: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include:
            "organization,allowedLocations,recipient,salesOrder,items.salesInvoiceable",
        };

        if(this.query)
        {
          params = {
            ...params,
            filter: {
              ...params.filter,
              ...(this.query ? { search: this.query } : {}),
            },
          };
        }

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterSalesOrder) {
          params = {
            ...params,
            filter: { ...params.filter, salesOrder: this.filterSalesOrder },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterRecipient) {
          if (this.filterRecipient.type && this.filterRecipient.id) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                recipient_type: this.filterRecipient.type,
                recipient_id: this.filterRecipient.id,
              },
            };
          }
        }
        if (this.selectedRecipient) {
          if (this.selectedRecipient.type && this.selectedRecipient.id) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                recipient_type: this.selectedRecipient.type,
                recipient_id: this.selectedRecipient.id,
              },
            };
          }
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: { ...params.filter, in_status: this.filterStatus },
          };
        }
        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("salesInvoices/list", params);
        this.salesInvoices = this.$store.getters["salesInvoices/list"];
        this.total = this.$store.getters["salesInvoices/listTotal"];
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async deleteSalesInvoice(id) {
      const confirmation = await swal.fire({
        title: this.$t("SALES_INVOICES.DELETE_THIS_SALES_INVOICE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("salesInvoices/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("SALES_INVOICES.SALES_INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewSalesInvoice(salesInvoice) {
      this.$emit("onViewSalesInvoice", salesInvoice);
    },

    editSalesInvoice(salesInvoice) {
      this.$emit("onEditSalesInvoice", salesInvoice);
    },

    sortChange({ prop, order }) {
      console.log({ prop, order });

      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
